<template>
  <div>
    <!-- <div class="flex flex-row hidden">
      <div class="lg:w-7/12 w-full border-center border-b-2 border-blue-900 mx-auto">
        <br />
        <br />
      </div>
      <div class="w-4/12 border-center border-b-2 border-blue-900 mx-auto">
        <br />
        <br />
      </div>
    </div> -->
    <div class="container mx-auto w-full h-full flex lg:flex-row flex-col">
      <div class="flex flex-col w-full">
        <section class="bg-white-100 py-16">
          <div class="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
            <div class="grid items-center grid-cols-1 md:grid-cols-2">
              <div>
                <h2 class="text-3xl font-bold leading-tight text-black dark:text-white sm:text-4xl lg:text-5xl mb-4">
                    <br class="block sm:hidden" />Elaine Ng - Founder
                </h2>
                <div>
                  An entrepreneur, management consultant and author.<br><br>
                  Elaine founded HR Business Solutions (HRBS) in 2001, and after building it to four offices in Hong Kong, Singapore, Shanghai, and Beijing,
                  she sold it to Mercer in 2015.
                  She then founded Management Resources Consultants (www.MRC-ASIA.com),
                  a Total Rewards, Predictive Analytics and Organisational Strategy Consulting practice in 2017. MRC helps companies design solutions for total rewards
                  as well as producing predictive analytics using machine learning, a subset of artificial intelligence. Super Compensation leverages on MRC machine learning algorithm
                  in providing pay data globally for benchmarking. <br><br>
                  Before starting her own consulting practice, Elaine worked for Mercer as Managing Director, Executive Compensation, and prior to that, Elaine was Director of Performance and Rewards based in Hong Kong. Prior to Mercer, she worked for Corporate Resources Group (CRG, which was later acquired by Mercer) as Regional Director, North Asia in Hong Kong. Before commencing her consulting career, Elaine held the position of Head of Human Resources in the Singapore General Hospital.<br><br>
                  Elaine is the author of three books. She wrote and published "GIVING UP SALARY - STARTING HRBS & SELLING IT TO MERCER" in October 2017, "I AM UNTAUGHT: TEACH ME THE WORD 我一无所知" in English & Chinese on Sep 30, 2022, "IN THE SHADOWS OF DARKNESS" on Sep 30, 2023, and "I AM WHO I AM" on Sep 30, 2024. She donated all proceeds from the sale of all her books are donated to charities. Elaine donated the sales proceeds of her first book to the Singapore Cancer Society. She gave all proceeds and donations of the second and third book to the Society of Saint Vincent de Paul (SSVP), a society that helps the poor regardless of religion.<br><br>
                  <!-- IN THE SHADOWS OF DARKNESS may be purchased online by clicking <a href="https://singapore.kinokuniya.com/bw/9789811880377" target="_blank" style="color:blue;text-decoration: underline; font-weight: bold;">Singapore Kinokuniya</a>
                  <br /><br />
                  For purchase of the hard copy via physical outlets,
                  <br/>
                  visit <b>Kinokuniya, Popular, and Times</b> bookstore at all outlets.
                  <br /><br />
                  To purchase <b>ENGLISH e-book</b>, please click this <a href="https://www.tusitalabooks.com/ebook/i-am-untaught/" target="_blank" style="color:blue;text-decoration: underline;"><b>URL</b></a>, and
                  for <b>CHINESE e-book</b>, please click this <a href="https://www.tusitalabooks.com/ebook/%e6%88%91%e4%b8%80%e6%97%a0%e6%89%80%e7%9f%a5/" target="_blank" style="color:blue;text-decoration: underline;"><b>URL</b></a> -->
                </div>
              </div>
              <div class="relative">
                <img class="relative w-half xl:max-w-lg xl:mx-auto 2xl:origin-bottom 2xl:scale-110" src="../assets/img/about_elaine.png" alt="" />
                <div class = "ml-20">
                  <span class="relative inline-block">
                <span class="absolute inline-block w-full bottom-0 h-1 bg-red-600 dark:bg-gray-900"></span>
                <span class="relative"> Have a question? </span>
                </span>
                <br class="block sm:" />Ask me on <a href="mailto:elaineng@mrc-asia.com" title=""
                    class="transition-all duration-200 text-sky-500 dark:text-sky-400 hover:text-sky-600 dark:hover:text-sky-500 hover:underline">elaineng@mrc-asia.com</a>
                </div>
              </div>
            </div>
            <h2 class="text-3xl font-bold leading-tight text-black dark:text-white sm:text-4xl lg:text-5xl mt-10">Curriculum Vitae (CV)</h2>
            <div class="bg-gray-50 mb-10">
              <div class="max-w-screen-lg mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4">
                <div class="rounded-lg overflow-hidden shadow-md bg-white">
                      <div class="px-6 py-8">
                          <h3 class="text-3xl font-bold mb-4 text-yellow-600">Work History</h3>
                          <ul class="px-4 pb-4 list-disc">
                              <li><b>2017 to now:</b> Founder & Director - Management Resources Consultants</li>
                              <li><b>2016:</b> Partner - Mercer</li>
                              <li><b>2001:</b> Founder & Managing Principal - HR Business Solutions (HRBS)</li>
                              <li><b>1999:</b> Managing Director - Executive Compensation & Performance & Rewards Director - Mercer</li>
                              <li><b>1995:</b> Relocated to Hong Kong Corporate Resources group, Director (North Asia)</li>
                              <li><b>1992:</b> Senior Consultant Corporate Resources Group  (CRG)</li>
                            </ul>
                      </div>
                  </div>
                  <div class="rounded-lg overflow-hidden shadow-md bg-white">
                      <div class="px-6 py-8">
                          <h3 class="text-3xl font-bold mb-4 text-blue-600">Expertise</h3>
                            <ul class="px-4 pb-4 list-disc">
                              <li>Organizational benchmarking & rightsizing</li>
                              <li>Executive compensation review</li>
                              <li>Stock plan design</li>
                              <li>Sales commission / incentive / bonus design</li>
                              <li>Job & salary grading</li>
                              <li>Benefits policies</li>
                              <li>Expat & hybrid compensation</li>
                              <li>HR strategy, policy & handbook</li>
                              <li>HR studies for investment</li>
                            </ul>
                      </div>
                  </div>
                  <div class="rounded-lg overflow-hidden shadow-md bg-white">
                      <div class="px-6 py-8">
                          <h3 class="text-3xl font-bold mb-4 text-red-600">Education</h3>
                          <ul class="px-4 pb-4 list-disc">
                              <li>MBA, Brunel University (UK)</li>
                              <li>Company Secretary, Institute of Chartered Secretaries and Administrators (UK)</li>
                              <li>Certified Compensation Professional of WorldatWork (former American Compensation Association)</li>
                              <li>Attended Advanced Oxford Management program</li>
                            </ul>
                      </div>
                  </div>
                  <div class="rounded-lg overflow-hidden shadow-md bg-white">
                      <div class="px-6 py-8">
                          <h3 class="text-3xl font-bold mb-4 text-green-600">Background</h3>
                          <ul class="px-4 pb-4 list-disc">
                              <li>Singapore Citizen</li>
                              <li>Hong Kong PR</li>
                              <li>30 years in Consulting</li>
                              <li>Founded HRBS in 2001</li>
                              <li>Founded MRC in 2017</li>
                              <li>Live in Hong Kong and Singapore</li>
                            </ul>
                      </div>
                  </div>
              </div>
            </div>
            <h2 class="text-3xl font-bold leading-tight text-black dark:text-white sm:text-4xl lg:text-5xl mb-4">Core Commitments</h2>
              <div class="mt-12 grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
                <div>
                  <div class="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                          stroke="currentColor" class="w-6 h-6">
                          <path stroke-linecap="round" stroke-linejoin="round"
                              d="M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0012 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75z" />
                      </svg>
                  </div>
                  <div class="mt-5">
                      <h3 class="text-lg font-medium text-gray-900">Mission</h3>
                      <p class="mt-2 text-base text-gray-500">We offer quantifiable human capital and remuneration solutions, combining machine intelligence and human tacit knowledge.</p>
                  </div>
                </div>
                  <div>
                    <div class="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                d="M4.26 10.147a60.436 60.436 0 00-.491 6.347A48.627 48.627 0 0112 20.904a48.627 48.627 0 018.232-4.41 60.46 60.46 0 00-.491-6.347m-15.482 0a50.57 50.57 0 00-2.658-.813A59.905 59.905 0 0112 3.493a59.902 59.902 0 0110.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.697 50.697 0 0112 13.489a50.702 50.702 0 017.74-3.342M6.75 15a.75.75 0 100-1.5.75.75 0 000 1.5zm0 0v-3.675A55.378 55.378 0 0112 8.443m-7.007 11.55A5.981 5.981 0 006.75 15.75v-1.5" />
                        </svg>

                    </div>
                    <div class="mt-5">
                        <h3 class="text-lg font-medium text-gray-900">Ethos</h3>
                        <p class="mt-2 text-base text-gray-500">Intellectually honest, rigorous, creative, practical, reliable, and timely in client services. Motivated by continuously learning and discovering new perspectives.</p>
                    </div>
                  </div>
                  <div>
                    <div class="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                            <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                        </svg>

                    </div>
                    <div class="mt-5">
                        <h3 class="text-lg font-medium text-gray-900">Vision</h3>
                        <p class="mt-2 text-base text-gray-500">To advance as the premier Asia consulting firm that provides practical answers to strategic problems impacting human resources motivation and productivity supported by A.I.</p>
                    </div>
                  </div>
              </div>
          </div>
        </section>
      </div>
    </div>
    <Testimonials class="bg-white" />
  </div>
</template>

<style scoped>
.bg-office {
  background-image: url('../assets/img/about_office_10.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left;
}
</style>

<script>
import Testimonials from '../components/Testimonials.vue';

export default {
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (to.hash && to.hash.length > 0) {
        vm.$nextTick(() => {
          document.getElementById(to.hash.substr(1)).scrollIntoView();
        });
      }
    });
  },
  beforeRouteUpdate(to, from, next) {
    next();
    if (to.hash && to.hash.length > 0) {
      this.$nextTick(() => {
        document.getElementById(to.hash.substr(1)).scrollIntoView();
      });
    }
  },
  components: {
    Testimonials,
  },
};
</script>
