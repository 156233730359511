<template>
  <div class="flex flex-col w-full banner bg-cover bg-center bg-fixed">
    <div class="flex justify-center">
      <div class="w-full flex flex-wrap">
        <div
          class="py-12 lg:px-40 px-20 lg:w-2/3 w-full
        bg-black h-104"
        >
          <span class="text-4xl font-bold text-white">
            <br />
            <br />
            Journey as an Entrepreneur
            <br />
            <span class="text-2xl font-bold text-white">- I Am Who I Am - The story of my god</span>
            <br />
            <span class="text-2xl font-bold text-white">- In The Shadows Of Darkness</span>
            <br />
            <span class="text-2xl font-bold text-white">- I Am Untaught - Teach Me The Word</span>
            <br />
            <span class="text-2xl font-bold text-white">-  Giving Up Salary</span>
          </span>
        </div>
        <img
          src="../assets/img/social_responsibility_book_header.jpg"
          alt="picture"
          class="lg:w-1/3 w-full lg:h-104 h-auto text-lg"
        />
        <div class="container md:px-5 py-12 item-center mx-auto">
          <div
            class="flex items-center w-full mx-auto border-b mb-10 pb-10 border-gray-400
          lg:flex-row flex-col elevation-2 hover:elevation-6 md:px-20 px-10 py-10"
          >
          <div class="flex flex-col">
              <span class="font-bold text-black text-3xl pb-2">I Am Who I Am</span>
              <span class="text-blue-700 text-xl border-b-2 pb-2 border-red-600">
                The story of my god
              </span>
              <br />
              <div class="flex flex-wrap">
                <div class="sm:mr-10 justify-left">
                  <p><strong>
                  Published in English and Chinese.
                  </strong></p>
                  <br/>
                  <p><i>
                    In her fourth and latest book, “I AM WHO I AM”: The Story of My God, Elaine Ng leads
                    readers to discover the seemingly unfathomable yet accessible, loving God, the God who
                    declared “I AM WHO I AM” (Exodus 3:14).
                  </i></p>
                  <br/>
                  <p>
                    She submits that God is not an option, but the foundation of the meaning of truth in life,
                    explicating through accounts with real people and experiences vis-à-vis the immutability of
                    the Scripture; that all things are transient, and God alone is eternal - the Alpha and the Omega;
                    and that God's will is all encompassing and on which everything depends. She illuminates the
                    fundamental tenets of the Christian faith with her trademark perspicacity, clarity and grace.
                    <br/>
                    <br/>
                    Quintessentially, she proclaims that Christianity is not a dogma. It is the belief in
                    the sacrifice of the body and blood of Jesus Christ on the Cross and His resurrection
                    to save sinners and reconcile us to God for eternity now and beyond.
                    <br/>
                    <br/>
                    God had spoken the world into existence through His Word, and by His breath, all
                    creatures - from dust we are and to dust we return while our spirit returns to God.
                    <br/>
                    <i>Do you believe this? If you do, you have something beyond the intellect.</i>
                  </p>
                  <!--<p>
                  Book launch videos of I AM UNTAUGHT: TEACH ME THE WORD, can be viewed at
                  <a href="https://www.youtube.com/watch?v=JK-yefTvtAs&list=PLN-RQMX9eQMo4dlbVmSbNRRp20xWgoOdI&index=1" style="color:blue;text-decoration: underline;"
                  target="_blank">here</a>
                  <br />
                  <span>Book is available at national library under code <b>248.86NG</b></span>
                  </p> -->
                </div>
                <div class="sm:mr-10 justify-center">
                <br />
                <!-- <a class="font-bold underline" href="https://singapore.kinokuniya.com/bw/9789811880377" target="_blank"> -->
                  <img
                    src="../assets/img/FullCover_Eng3.jpg"
                    alt="picture"
                    class="w-full"
                  />
                <!-- </a> -->
                  <div class = "flex flex-wrap" v-show="false">
                    <div class ="w-1/2">
                      <span class="font-bold text-red-500 text-xl text-center">
                          Available through online channels!
                      </span>
                      <br/>
                      - <a href = "https://singapore.kinokuniya.com/bw/9789811880377" target="_blank" style="color:rgb(0, 0, 0);text-decoration: underline;"><b>Kinokuniya</b></a>
                      <!-- <br/>
                      - <a href = "https://www.tusitalabooks.com/ebook/%e6%88%91%e4%b8%80%e6%97%a0%e6%89%80%e7%9f%a5/" target="_blank" style="color:rgb(0, 0, 0);text-decoration: underline;"><b>e-book</b></a> -->
                    </div>
                    <div class ="w-1/2">
                      <span class="font-bold text-red-500 text-xl text-center">
                        Availability through outlets
                      </span>
                      <br/>
                      <span><b>Kinokuniya</b></span>
                      <!-- <br/>
                      <span><b>Populuar BookStore</b></span>
                      <br/>
                      <span><b>Times BookStore</b></span>
                      <br/> -->
                      <br/>
                      <!-- - <a style="color:blue;text-decoration: underline;"><b>Popular BookStore (Islandwide)</b></a>
                      <br/>
                      - <a href = "https://g.co/kgs/xtHQmc" target="_blank" style="color:blue;text-decoration: underline;"><b>Times Waterway Point</b></a>
                      <br/>
                      - <a href = "https://g.co/kgs/AJwjHZ" target="_blank" style="color:blue;text-decoration: underline;"><b>Times Plaza Singapura</b></a>
                      <br/>
                      - <a href = "https://g.co/kgs/J3zY7z" target="_blank" style="color:blue;text-decoration: underline;"><b>Times Jelita</b></a> -->
                    </div>
                  </div>
                  <br />
                  <!-- <a class="font-bold underline" href="https://singapore.kinokuniya.com/bw/9789811880377" target="_blank"> -->
                  <img
                    src="../assets/img/FullCover_Chi3.jpg"
                    alt="picture"
                    class="w-full"
                  />
                <!--</a>-->
                  <div class = "flex flex-wrap" v-show="false">
                    <!-- <div class ="w-1/2">
                      <span class="font-bold text-red-500 text-xl text-center">
                        Availability through online channels
                      </span>
                      <br/>
                      - <a href = "https://singapore.kinokuniya.com/bw/9789811852671" target="_blank" style="color:rgb(0, 0, 0);text-decoration: underline;"><b>Kinokuniya</b></a>
                      <br/>
                      - <a href = "https://www.amazon.sg/dp/9811852677/ref=cm_sw_em_r_mt_dp_NK5KM460063GJBTW8WZ9" target="_blank" style="color:rgb(0, 0, 0);text-decoration: underline;"><b>Amazon</b></a>
                      <br/>
                      - <a href = "https://www.tusitalabooks.com/ebook/i-am-untaught/" target="_blank" style="color:rgb(0, 0, 0);text-decoration: underline;"><b>e-book</b></a>
                      <br />
                    </div> -->
                    <div class ="w-2/2">
                      <!--<span class="font-bold text-red-500 text-xl text-center">
                        Availability through outlets
                      </span>
                      <br/>
                      <span><b>Kinokuniya</b></span>
                      <br/>
                       <span><b>Populuar BookStore</b></span>
                      <br/>
                      <span><b>Times BookStore</b></span>
                      <br/>
                      <a style="color:blue;text-decoration: underline;"><b>Popular BookStore (Islandwide)</b></a>
                      <br/>  -->
                    </div>
                  </div>
                <br/>
                <span class="text-3xl">
                  <b>You Can Grab The Book Absolutely <span class = "font-bold text-red-500 text-l">FREE!</span></b>
                </span>
                <br>
                <br>
                <span class="text-xl">
                  <p>
                    If you wish to have a copy of any or all of the books, please extend your kindness by <b>donating the equivalent of US$25 or SG$33 or HK$200 per copy of book to any charity organisation</b> (no receipt needed, God knows), and arrange for your book collection at 1 Scotts Road, Shaw Centre, #26-01.
                    <br/>Write to <a class = "font-bold text-red-500 text-l"
                    href="mailto:enquiry@mrc-asia.com?subject=[Book Enquiry] I Am Who I Am - The story of my god">enquiry@mrc-asia.com</a> for prior appointment and let us know which book/s. You may send courier to do the collection for you. You'll have our book/s in your hands, while spreading goodwill to others. Make these books your gifts to others. Let's make a difference together!
                  </p>
                </span>
                <!-- <br/>
                <br/>
                <div class = "flex flex-wrap" v-show="true">
                  <div class ="w-1/2">
                    <span class="font-bold text-red-500 text-xl text-center">
                      Book Launch Event In Singapore
                    </span>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/ILByNcn1zIA?si=3mxJ60ExTebuUckY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                  </div>
                  <div class ="w-2/2">
                    <span class="font-bold text-red-500 text-xl text-center">
                      Book Launch Event In Hong Kong
                    </span>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/WInswYI8UFk?si=Py4CSVvtwjuN6ydn" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
          <div
            class="flex items-center w-full mx-auto border-b mb-10 pb-10 border-gray-400
          lg:flex-row flex-col elevation-2 hover:elevation-6 md:px-20 px-10 py-10"
          >
            <div class="flex flex-col">
              <span class="font-bold text-black text-3xl pb-2">In The Shadows Of Darkness</span>
              <span class="text-blue-700 text-xl border-b-2 pb-2 border-red-600">
                What Do You Believe?
              </span>
              <br />
              <div class="flex flex-wrap">
                <div class="sm:mr-10 justify-left">
                  <p><strong>
                  Published in English and Chinese.
                  </strong></p>
                  <br/>
                  <p><i>
                  If God is omnipotent and loving, why does he allow us to die of sicknesses and
                  pains; why does he permit evil to exist?
                  </i></p>
                  <br/>
                  <p>
                    These hard questions evince the seemingly incomprehensibility of the reality of
                    human suffering vis-à-vis the central character of God – love. Through her own journey of
                    experience of loss, keen observations of history and careful study of the Scriptures, author
                    Elaine Ng attempts to answer the foremost question: “Why God?”
                    <br/>
                    <br/>
                    Dubbed “deeply moving”, “thought-provoking” and “invigorating”, In the
                    Shadows of Darkness: What Do You Believe? assiduously guides readers to critically examine
                    the Scriptures, to gain insights into the unchanging character of God, his sovereignty
                    and his ultimate plan for mankind. It further explores the significance of prayers, fasting and
                    faith in relation to the spiritual connection between Man and God.
                    <br/>
                    <br/>
                    The book aims to bring solace to all who need a saviour and renewal in life. It is written
                    with all in mind, followers of Christ or not - to inspire all who are walking through darkness,
                    seeking discernment, meaning and hope.
                  </p>
                  <br/>
                  <p>
                    All sales proceeds are donated to the Society of Saint Vincent De Paul which helps the underprivileged regardless of religion.
                    <br/><br/>
                    Book is sold at <a class="font-bold underline" href="https://singapore.kinokuniya.com/bw/9789811880377" target="_blank">Kinokuniya bookstores</a> in Singapore. Click <a class="font-bold underline" href="https://singapore.kinokuniya.com/bw/9789811880377" target="_blank">here</a> to buy online.
                  </p>
                  <!--<p>
                  Book launch videos of I AM UNTAUGHT: TEACH ME THE WORD, can be viewed at
                  <a href="https://www.youtube.com/watch?v=JK-yefTvtAs&list=PLN-RQMX9eQMo4dlbVmSbNRRp20xWgoOdI&index=1" style="color:blue;text-decoration: underline;"
                  target="_blank">here</a>
                  <br />
                  <span>Book is available at national library under code <b>248.86NG</b></span>
                  </p> -->
                </div>
                <div class="sm:mr-10 justify-center">
                <br />
                <a class="font-bold underline" href="https://singapore.kinokuniya.com/bw/9789811880377" target="_blank">
                  <img
                    src="../assets/img/FullCover_Eng2.jpg"
                    alt="picture"
                    class="w-full"
                  />
                </a>
                  <div class = "flex flex-wrap" v-show="false">
                    <div class ="w-1/2">
                      <span class="font-bold text-red-500 text-xl text-center">
                          Available through online channels!
                      </span>
                      <br/>
                      - <a href = "https://singapore.kinokuniya.com/bw/9789811880377" target="_blank" style="color:rgb(0, 0, 0);text-decoration: underline;"><b>Kinokuniya</b></a>
                      <!-- <br/>
                      - <a href = "https://www.tusitalabooks.com/ebook/%e6%88%91%e4%b8%80%e6%97%a0%e6%89%80%e7%9f%a5/" target="_blank" style="color:rgb(0, 0, 0);text-decoration: underline;"><b>e-book</b></a> -->
                    </div>
                    <div class ="w-1/2">
                      <span class="font-bold text-red-500 text-xl text-center">
                        Availability through outlets
                      </span>
                      <br/>
                      <span><b>Kinokuniya</b></span>
                      <!-- <br/>
                      <span><b>Populuar BookStore</b></span>
                      <br/>
                      <span><b>Times BookStore</b></span>
                      <br/> -->
                      <br/>
                      <!-- - <a style="color:blue;text-decoration: underline;"><b>Popular BookStore (Islandwide)</b></a>
                      <br/>
                      - <a href = "https://g.co/kgs/xtHQmc" target="_blank" style="color:blue;text-decoration: underline;"><b>Times Waterway Point</b></a>
                      <br/>
                      - <a href = "https://g.co/kgs/AJwjHZ" target="_blank" style="color:blue;text-decoration: underline;"><b>Times Plaza Singapura</b></a>
                      <br/>
                      - <a href = "https://g.co/kgs/J3zY7z" target="_blank" style="color:blue;text-decoration: underline;"><b>Times Jelita</b></a> -->
                    </div>
                  </div>
                  <br />
                  <a class="font-bold underline" href="https://singapore.kinokuniya.com/bw/9789811880377" target="_blank">
                  <img
                    src="../assets/img/FullCover_Chi2.jpg"
                    alt="picture"
                    class="w-full"
                  />
                </a>
                  <div class = "flex flex-wrap" v-show="false">
                    <!-- <div class ="w-1/2">
                      <span class="font-bold text-red-500 text-xl text-center">
                        Availability through online channels
                      </span>
                      <br/>
                      - <a href = "https://singapore.kinokuniya.com/bw/9789811852671" target="_blank" style="color:rgb(0, 0, 0);text-decoration: underline;"><b>Kinokuniya</b></a>
                      <br/>
                      - <a href = "https://www.amazon.sg/dp/9811852677/ref=cm_sw_em_r_mt_dp_NK5KM460063GJBTW8WZ9" target="_blank" style="color:rgb(0, 0, 0);text-decoration: underline;"><b>Amazon</b></a>
                      <br/>
                      - <a href = "https://www.tusitalabooks.com/ebook/i-am-untaught/" target="_blank" style="color:rgb(0, 0, 0);text-decoration: underline;"><b>e-book</b></a>
                      <br />
                    </div> -->
                    <div class ="w-2/2">
                      <span class="font-bold text-red-500 text-xl text-center">
                        Availability through outlets
                      </span>
                      <br/>
                      <span><b>Kinokuniya</b></span>
                      <br/>
                      <!-- <span><b>Populuar BookStore</b></span>
                      <br/>
                      <span><b>Times BookStore</b></span>
                      <br/>
                      <a style="color:blue;text-decoration: underline;"><b>Popular BookStore (Islandwide)</b></a>
                      <br/>  -->
                    </div>
                  </div>
                <br/>
                <span class="text-xl">
                  Contact <a class = "font-bold text-red-500 text-l"
                  href="mailto:enquiry@mrc-asia.com?subject=[Book Enquiry] In The Shadow Of Darkness What Do You Believe?">enquiry@mrc-asia.com</a> for any assistance.
                </span>
                <br/>
                <br/>
                <div class = "flex flex-wrap" v-show="true">
                  <div class ="w-1/2">
                    <span class="font-bold text-red-500 text-xl text-center">
                      Book Launch Event In Singapore
                    </span>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/ILByNcn1zIA?si=3mxJ60ExTebuUckY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                  </div>
                  <div class ="w-2/2">
                    <span class="font-bold text-red-500 text-xl text-center">
                      Book Launch Event In Hong Kong
                    </span>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/WInswYI8UFk?si=Py4CSVvtwjuN6ydn" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
          <div
            class="flex items-center w-full mx-auto border-b mb-10 pb-10 border-gray-400
          lg:flex-row flex-col elevation-2 hover:elevation-6 md:px-20 px-10 py-10"
          >
            <div class="flex flex-col">
              <span class="font-bold text-black text-3xl pb-2"> I Am Untaught</span>
              <span class="text-blue-700 text-xl border-b-2 pb-2 border-red-600">
                Teach Me The Word
              </span>
              <br />
              <div class="flex flex-wrap">
                <div class="sm:mr-10 justify-left">
                  <p><strong>
                  Published in English and Chinese.
                  </strong></p>
                  <br/>
                  <p><i>
                  No one escapes death.  And no one knows when!  What happens to the body, soul,
                  and spirit
                  after death?
                  </i></p>
                  <br/>
                  <p>
                  This book is born out of the author’s desperate yet determined walk through the
                  valley of
                  the shadows of death with her husband who suffered simultaneously from cancer
                  relapse and
                  heart failure. The fight for his life sparked a journey that saw them seeking
                  divine
                  intervention and receiving God’s gracious providence. But why did God not heal
                  him all
                  the way? Founded on the Word of God, I AM UNTAUGHT:TEACH ME THE WORD aspires to
                  answer the
                  spiritual questions surrounding divine healing, and body, soul, and spirit after
                  death,
                  bringing confidence and hope to all readers burdened by questions of “why” and
                  “why me?”
                  </p>
                  <br/>
                  <p>
                  Book launch videos of I AM UNTAUGHT: TEACH ME THE WORD, can be viewed at
                  <a href="https://www.youtube.com/watch?v=JK-yefTvtAs&list=PLN-RQMX9eQMo4dlbVmSbNRRp20xWgoOdI&index=1" style="color:blue;text-decoration: underline;"
                  target="_blank">here</a>
                  <br />
                   <span>Book is available at national library under code <b>248.86NG</b></span>
                  </p>
                </div>
                <div class="sm:mr-10 justify-center">
                <br />
                  <img
                    src="../assets/img/FullCover_Eng.jpg"
                    alt="picture"
                    class="w-full"
                  />
                <!-- <div class = "flex flex-wrap">
                  <div class ="w-1/2">
                    <span class="font-bold text-red-500 text-xl text-center">
                      Availability through online channels
                    </span>
                    <br/>
                    - <a href = "https://singapore.kinokuniya.com/bw/9789811852671" target="_blank" style="color:rgb(0, 0, 0);text-decoration: underline;"><b>Kinokuniya</b></a>
                    <br/>
                    - <a href = "https://www.amazon.sg/dp/9811852677/ref=cm_sw_em_r_mt_dp_NK5KM460063GJBTW8WZ9" target="_blank" style="color:rgb(0, 0, 0);text-decoration: underline;"><b>Amazon</b></a>
                    <br/>
                    - <a href = "https://www.tusitalabooks.com/ebook/i-am-untaught/" target="_blank" style="color:rgb(0, 0, 0);text-decoration: underline;"><b>e-book</b></a>
                    <br />
                  </div>
                  <div class ="w-1/2">
                    <span class="font-bold text-red-500 text-xl text-center">
                      Availability through outlets
                    </span>
                    <br/>
                    <span><b>Kinokuniya</b></span>
                    <br/>
                    <span><b>Populuar BookStore</b></span>
                    <br/>
                    <span><b>Times BookStore</b></span>
                    <br/>
                    <a style="color:blue;text-decoration: underline;"><b>Popular BookStore (Islandwide)</b></a>
                    <br/>
                    - <a href = "https://g.co/kgs/xtHQmc" target="_blank" style="color:blue;text-decoration: underline;"><b>Kinokuniya</b></a>
                    <br/>
                    - <a href = "https://g.co/kgs/AJwjHZ" target="_blank" style="color:blue;text-decoration: underline;"><b>Populuar BookStore</b></a>
                    <br/>
                    - <a href = "https://g.co/kgs/J3zY7z" target="_blank" style="color:blue;text-decoration: underline;"><b>Times BookStore</b></a>
                    <br/>
                  </div>
                </div> -->
                <br />
                  <img
                    src="../assets/img/FullCover_Chi.jpg"
                    alt="picture"
                    class="w-full"
                  />
                <!-- <div class = "flex flex-wrap">
                  <div class ="w-1/2">
                    <span class="font-bold text-red-500 text-xl text-center">
                      Availability through online channels
                    </span>
                    <br/>
                    - <a href = "https://singapore.kinokuniya.com/bw/9789811852794" target="_blank" style="color:rgb(0, 0, 0);text-decoration: underline;"><b>Kinokuniya</b></a>
                    <br/>
                    - <a href = "https://www.tusitalabooks.com/ebook/%e6%88%91%e4%b8%80%e6%97%a0%e6%89%80%e7%9f%a5/" target="_blank" style="color:rgb(0, 0, 0);text-decoration: underline;"><b>e-book</b></a>
                  </div>
                  <div class ="w-1/2">
                    <span class="font-bold text-red-500 text-xl text-center">
                      Availability through outlets
                    </span>
                    <br/>
                    <span><b>Kinokuniya</b></span>
                    <br/>
                    <span><b>Populuar BookStore</b></span>
                    <br/>
                    <span><b>Times BookStore</b></span>
                    <br/>
                    <br/>
                    - <a style="color:blue;text-decoration: underline;"><b>Popular BookStore (Islandwide)</b></a>
                    <br/>
                    - <a href = "https://g.co/kgs/xtHQmc" target="_blank" style="color:blue;text-decoration: underline;"><b>Times Waterway Point</b></a>
                    <br/>
                    - <a href = "https://g.co/kgs/AJwjHZ" target="_blank" style="color:blue;text-decoration: underline;"><b>Times Plaza Singapura</b></a>
                    <br/>
                    - <a href = "https://g.co/kgs/J3zY7z" target="_blank" style="color:blue;text-decoration: underline;"><b>Times Jelita</b></a>
                  </div>
                </div> -->
                <br/>
                <b>For purchase of book, Please contact <a class = "font-bold text-red-500 text-l"
                href="mailto:enquiry@mrc-asia.com?subject= [Book Enquiry] I Am Untaught Teach Me The Word">enquiry@mrc-asia.com</a></b>
              </div>
            </div>
          </div>
        </div>
          <div
            class="flex items-center w-full mx-auto border-b mb-10 pb-10 border-gray-400
          lg:flex-row flex-col elevation-2 hover:elevation-6 md:px-20 px-10 py-10"
          >
            <div class="flex flex-col">
              <span class="font-bold text-black text-3xl pb-2"> Giving Up Salary</span>
              <span class="text-blue-700 text-xl border-b-2 pb-2 border-red-600">
                Starting HRBS and selling it to Mercer
              </span>
              <br />
              <div class="flex flex-wrap">
                <div class="sm:mr-10 justify-center">
                  <img
                    src="../assets/img/social_responsibility_book.jpg"
                    alt="picture"
                    class="w-full"
                  />
                <b>For purchase of book, Please contact <a class = "font-bold text-red-500 text-l"
                href="mailto:enquiry@mrc-asia.com?subject= [Book Enquiry] Giving Up Salary">enquiry@mrc-asia.com</a></b>
                </div>
                <div class="flex-grow sm:text-left text-center mt-6 sm:mt-0 w-1/3">
                  <span class="font-bold text-red-500 text-xl">
                    AUTHOR: ELAINE NG
                  </span>
                  <br />
                  <br />
                  This book is not Elaine’s biography. It is about her experience as an
                  entrepreneur, and how she successfully grew her consulting business in the face of
                  huge, established global competitors. Their size dwarfed HR Business Solutions
                  (HRBS), the company that she started in 2001 and eventually sold after nearly
                  one-and-a-half decades. It is also about why she started her second company,
                  Management Resources Consultants (MRC) in 2017.
                  <br />
                  <br />
                  Elaine is all too familiar with devastations arising from cancer. Cancer has
                  claimed the lives of her father and two uncles. It almost claimed her and her
                  husband’s life too. She battled and recovered from stage 2 breast cancer while her
                  husband recovered from stage 3 sarcoma.
                  <br />
                  <br />
                  In just five weeks, 278 copies of the book have been sold! This is despite that
                  GIVING UP SALARY has yet to be distributed via book stores and has not received
                  any publicity.
                  <br />
                  <br />
                  Book is available at national library under code <b>650.1 (Non-Fiction)</b>
                </div>
              </div>
            </div>
          </div>
          <div
            class="flex items-center w-full mx-auto border-b pb-10 mb-10 border-gray-400
          lg:flex-row flex-col elevation-2 hover:elevation-6 md:px-20 px-10 py-10"
          >
            <div class="flex flex-col">
              <span class="font-bold text-black text-3xl border-b-2 pb-4 border-red-600">
                In partnership with Society of St. Vincent de Paul Singapore (SSVP)
              </span>
              <div class="flex flex-wrap">
                <div class="flex-grow sm:text-left text-center mt-4 sm:mt-0 w-1/3">
                  <span class="font-bold text-red-500 text-xl text-center">
                    All book sales and donations of "In The Shadows Of Darkness" and "I Am Untaught" will be 100% given to Society of
                    St. Vincent de Paul (SSVP)
                  </span>
                  <br />
                  <br />
                  A charitable organization which helps the poor and underprivileged
                  of all religions.
                  <br />
                  <br />
                  <b>Elaine Ng</b>
                </div>
                <!--
                <img
                  src="../assets/img/social_responsibility_MRC-collage.jpg"
                  alt="picture"
                  class="lg:w-1/3 w-full"
                />
                -->
              </div>
            </div>
          </div>
          <div
            class="flex items-center w-full mx-auto border-b pb-10 mb-10 border-gray-400
          lg:flex-row flex-col elevation-2 hover:elevation-6 md:px-20 px-10 py-10"
          >
            <div class="flex flex-col">
              <span class="font-bold text-black text-3xl border-b-2 pb-4 border-red-600">
                In partnership with Singapore Cancer Society
              </span>
              <div class="flex flex-wrap">
                <div class="flex-grow sm:text-left text-center mt-4 sm:mt-0 w-1/3">
                  <span class="font-bold text-red-500 text-xl text-center">
                    All proceeds from sale of "GIVING UP SALARY" will be 100% donated to the
                    Singapore Cancer Society
                  </span>
                  <br />
                  <br />
                  Wishing to do her part to raise cancer awareness and funds to help patients
                  plagued by cancer, especially the lower income families, a book launch of GIVING
                  UP SALARY was organized on Oct 27, 2017 evening at Singapore Clarke Quay Hotel.
                  Attended by about 100 guests, 98 copies of the book were snapped up just that
                  night!
                  <b
                    >Elaine’s husband also donated an additional S$100,000 to the Singapore Cancer
                    Society in his personal capacity.</b
                  >
                  <br />
                  <br />
                  On 10 March 2018, Elaine was invited to
                  <b>share her experiences as a cancer caregiver and survivor</b> at the SCS Relay
                  for Life event 2018. This event was opened and graced by
                  <b>Singapore President Halimah Yacob</b>. Over <b>6,500</b> participants supported
                  and raised more than <b>$830,000</b> at this meaningful event.Photos and videos
                  can be found at our milestones page.
                  <br />
                  <br />
                  <b>"Life without a heart is empty." </b>
                  <br />
                  <br />
                  <b>Elaine Ng</b>
                  <br />
                  <br />
                  <b>To get a copy of this book, Please contact
                  <a class = "font-bold text-red-500 text-l" href="mailto:enquiry@mrc-asia.com">
                  enquiry@mrc-asia.com</a></b>
                </div>
                <img
                  src="../assets/img/social_responsibility_MRC-collage.jpg"
                  alt="picture"
                  class="lg:w-1/3 w-full"
                />
              </div>
            </div>
          </div>
          <!--
          <div
            class="flex items-center w-full mx-auto border-b pb-10 mb-10 border-gray-400
          lg:flex-row flex-col elevation-2 hover:elevation-6 md:px-20 px-10 py-10"
          >
            <div class="flex flex-col w-full">
              <span class="font-bold text-black text-3xl border-b-2 pb-4 border-red-600">
                Available at Singapore Bookstores
              </span>
              <br />
              <div class="flex flex-wrap">
                <div class="flex-grow sm:text-left text-center mt-6 sm:mt-0 w-1/3">
                  <img
                    src="../assets/img/social_responsibility_book_shop2.jpg"
                    alt="picture"
                    class="lg:w-full w-full lg:pr-10 pr-0 mx-auto"
                  />
                </div>
                <div class="flex-grow sm:text-left text-center mt-6 sm:mt-0 md:w-1/3 w-full">
                  <span class="font-bold text-red-700 text-xl text-center">
                    100% will be donated to the Singapore Cancer Society
                  </span>
                  <br />
                  <br />
                  <span class="font-bold text-red-500 text-xl text-center">
                    Do get a copy! in Singapore:
                  </span>
                  <br />
                  <br />
                  <b style = "color: #2b6cb0"><u>I AM Untaught</u></b>
                  <br />
                  Kinokuniya (Takashimaya)
                  <br />
                  <span class="">
                    For Direct Purchase Please fill in the form below:
                    <br />
                    <b>USD ?? / SGD 31.99 / HKD ??? / RMB ???</b>
                  </span>
                  <br />
                  <br />
                  <b style = "color: #2b6cb0"><u>Giving Up Salary</u></b>
                  <br />
                  Kinokuniya (all outlets)
                  <br />
                  Times Bookstore (all outlets)
                  <br />
                  Select Books (main outlet) Has been selected by Singapore National Library to be
                  stocked in 15 of its branches,
                  <b>code 650.1 (Non-Fiction)</b>.
                  <br />
                  <br />
                  <span class="">
                    For Direct Purchase Please fill in the form below:
                    <br />
                    <b>USD 30 / SGD 42 / HKD 235 / RMB 210</b>
                  </span>
                  <form class="mt-6">
                    <label
                      for="email"
                      class="block mt-2 text-md font-semibold
                    text-gray-600 uppercase"
                      >E-mail</label
                    >
                    <input
                      v-model="email"
                      id="email"
                      type="email"
                      name="email"
                      placeholder="Enter Email Here"
                      autocomplete="email"
                      class="block w-full p-3 mt-2 text-gray-700
                    bg-gray-200 appearance-none focus:outline-none focus:bg-gray-300
                    focus:shadow-inner"
                      required
                    />
                    <div class="flex flex-row space-x-4">
                      <div class="flex flex-col w-1/3">
                        <label
                          for="location"
                          class="block mt-2 text-md font-semibold
                        text-gray-600 uppercase flex-column"
                          >Location</label
                        >
                        <input
                          v-model="location"
                          id="location"
                          type="location"
                          name="location"
                          placeholder="Location"
                          autocomplete="location"
                          class="block w-full p-3 mt-2 text-gray-700
                        bg-gray-200 appearance-none focus:outline-none focus:bg-gray-300
                        focus:shadow-inner"
                          required
                        />
                      </div>
                      <div class="flex flex-col w-1/3">
                        <div class="h-8"></div>
                        <button
                          @click="purchaseBook"
                          type="button"
                          class="block justify-center mt-2 py-3 px-4 border border-transparent
                            text-sm leading-5 font-medium text-white bg-red-600
                            focus:outline-none elevation-2 hover:elevation-6 active:elevation-1
                            transition duration-150 ease-in-out"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          -->
          <div
            class="items-center w-full mx-auto border-b pb-10 border-gray-400
            lg:flex-row flex-col elevation-2 hover:elevation-6 md:px-20 px-10 py-10"
          >
            <BookReview class="bg-white" />
          </div>
        </div>
      </div>
    </div>

    <Alert :bottom="alertBottom" :timeout="8000" v-model="purchaseAlert">
      <div class="px-6 py-4 lg:w-144 w-112 flex lg:text-base text-sm justify-between">
        <div class="pr-6">
          {{ purchaseMsg }}
        </div>
        <button
          @click="purchaseAlert = false"
          class="text-pink-500 focus:outline-none font-medium text-sm"
        >
          CLOSE
        </button>
      </div>
    </Alert>
  </div>
</template>

<style scoped></style>

<script>
import recaptcha from '@/recaptcha';
import Alert from '@/components/Alert.vue';
import isEmailValid from '@/util/isEmailValid';
import BookReview from '../components/BookReview.vue';
import { book } from '../api/public/rpcs';

export default {
  name: 'Home',
  data() {
    return {
      purchaseAlert: false,
      purchaseMsg: '',
      alertBottom: false,
      email: '',
      location: '',
      quantity: '',
      options: Object.freeze({
        currentPage: 0,
        tracking: false,
        thresholdDistance: 100,
        thresholdTime: 300,
        infinite: 4,
        slidesToScroll: 4,
        loop: true,
      }),
    };
  },
  methods: {
    purchaseBook() {
      if (!this.email) {
        this.alertBottom = true;
        this.purchaseAlert = true;
        this.purchaseMsg = 'Please enter your email address';
        return;
      }
      if (!isEmailValid(this.email)) {
        this.alertBottom = true;
        this.purchaseAlert = true;
        this.purchaseMsg = 'Please enter a valid email';
        return;
      }

      recaptcha('purchase_book')
        .then((token) => book({
          email: this.email,
          location: this.location,
          quantity: +this.quantity,
          recaptchaToken: token,
        }))
        .then(() => {
          this.alertBottom = false;
          this.purchaseAlert = true;
          this.purchaseMsg = 'Thank you for your interest. We will contact you soon.';
        })
        .catch(() => {
          this.alertBottom = false;
          this.purchaseAlert = true;
          this.purchaseMsg = 'Error encountered. Please contact enquiry@mrc-asia.com to be updated on our exciting programmes ahead.';
        });
    },
  },
  components: {
    BookReview,
    Alert,
  },
};
</script>
